<template>
	<!-- Multiple div to prevent :class on parent and child conflicts -->
	<div>
		<div
			class="ws-recipient p-4"
			:class="{
				'ws-recipient__error': props.hasError,
				'ws-recipient__active': active,
				'ws-recipient__disabled': props.disabled
			}"
			data-testid="ws-recipient"
			@click="editMode"
		>
			<!-- Active Side -->
			<div v-if="active || props.hasError">
				<ws-form-select
					:label="$t('recipient')"
					:placeholder="'-'"
					:options="users"
					:model-value="selectedRecipient.localValue"
					:allow-null="false"
					@update:model-value="updateSelectedRecipient"
					data-testid="ws-recipient-select"
				/>

				<slot name="options" :recipient="selectedRecipient"></slot>

				<div
					class="buttons is-justify-content-end mt-5"
					data-testid="ws-recipient-buttons"
				>
					<ws-button
						@click.stop="cancel"
						is-subtle
						data-testid="ws-recipient-cancel"
					>
						{{ $t("cancel") }}
					</ws-button>
					<ws-button
						is-primary
						is-outlined
						data-testid="ws-recipient-validate"
						:disabled="
							!selectedRecipient.value ||
							!email.$validator(selectedRecipient?.value)
						"
						@click.stop="validateRecipient"
					>
						{{ $t("validate-recipient") }}
					</ws-button>
				</div>
			</div>

			<!-- Idle Side -->
			<div
				v-else
				class="is-flex is-justify-content-space-between is-align-items-center"
				:class="{ 'cursor-pointer': !props.disabled }"
				data-testid="ws-recipientidle"
			>
				<p class="mb-0">
					<span
						v-if="hasNotAccess"
						class="title is-6"
						data-testid="ws-recipient-name"
					>
						{{ $t("recipient-without-access") }}
					</span>
					<span
						v-else
						class="title is-6"
						data-testid="ws-recipient-name"
					>
						{{
							recipientValidated?.fullName ||
							recipientValidated?.username ||
							recipientValidated?.value
						}}
					</span>
					<span
						class="is-size-7 is-block has-text-grey-500"
						data-testid="ws-recipient-info"
					>
						<template
							v-if="
								((recipientValidated?.username &&
									recipientValidated?.fullName) ||
									hasNotAccess) &&
								showInfo('email')
							"
						>
							{{
								recipientValidated?.username ||
								recipientValidated?.value
							}}
						</template>
						<template
							v-if="
								showInfo('phoneNumber') &&
								recipientValidated?.username &&
								recipientValidated?.phoneNumber
							"
						>
							-
						</template>
						<template
							v-if="
								showInfo('phoneNumber') &&
								recipientValidated?.phoneNumber
							"
						>
							{{ recipientValidated?.phoneNumber }}
						</template>
					</span>
				</p>
				<div class="buttons" data-testid="ws-recipient-idle-buttons">
					<ws-button
						v-if="!props.disabled"
						is-subtle
						@click.stop="emit('delete')"
						data-testid="ws-recipient-delete"
					>
						<template #icon>
							<ws-icon icon="trash"></ws-icon
						></template>
					</ws-button>
				</div>
			</div>
		</div>
		<p v-if="props.hasError" class="is-size-7 has-text-danger-500">
			<slot name="errorMessage"></slot>
		</p>
	</div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { email } from "@vuelidate/validators";
const WITHOUT_ACCESS = "without-access";
const { t: $t } = useI18n();

defineExpose({ validateRecipient });
/**
 * Model as object to deal with more complex value in slot
 * than a single email or phoneNumber
 * to: email address as main value
 */
const model = defineModel({
	type: Object,
	required: true
});
const props = defineProps({
	availableUsers: {
		type: Array,
		required: true
	},
	hasError: {
		type: Boolean,
		required: false,
		default: false
	},
	initialState: {
		type: Boolean,
		required: false,
		default: true
	},
	enabledInfos: {
		type: Array,
		required: false,
		default: () => ["email", "phoneNumber"]
	},
	disabled: {
		type: Boolean,
		required: false,
		default: false
	}
});
const emit = defineEmits(["delete", "update:modelValue"]);
const active = ref(props.initialState);
const selectedRecipient = ref(model.value);

const users = computed(() => [
	{
		value: WITHOUT_ACCESS,
		label: $t("recipient-without-access"),
		username: ""
	},
	...props.availableUsers
		.map((user) => ({
			...user,
			value: user.username,
			label: user.fullName
				? `${user.fullName} (${user.username})`
				: user.username
		}))
		.sort(function (a, b) {
			const x = a.label.toLowerCase();
			const y = b.label.toLowerCase();
			return x.localeCompare(y);
		})
]);
const recipientValidated = computed(() => {
	return model.value?.localValue === WITHOUT_ACCESS
		? model.value
		: users.value.find((user) => user.username === model.value.value);
});

const hasNotAccess = computed(
	() => recipientValidated.value?.localValue === WITHOUT_ACCESS
);

const isEmailExternal = computed(
	() => !users.value.map((user) => user.username).includes(model.value.value)
);

const showInfo = computed(() => (info) => {
	return props.enabledInfos.includes(info);
});

onMounted(() => {
	if (model.value.value) {
		active.value = false;
		updateSelectedRecipient(model.value.value);
	}
	if (model.value.value && isEmailExternal.value) {
		selectedRecipient.value.localValue = WITHOUT_ACCESS;
		selectedRecipient.value.username = model.value.value;
		validateRecipient();
	}
});

function editMode() {
	if (!active.value) {
		active.value = true;
	}
}

function cancel() {
	if (!model.value.value) {
		emit("delete");
	}
	active.value = false;
}

/**
 * Workaround to grandchild reactivity issue from defineModel
 * https://github.com/vuejs/core/discussions/10538
 * @param to
 */
function updateSelectedRecipient(localValue) {
	selectedRecipient.value = {
		...selectedRecipient.value,
		value: localValue === WITHOUT_ACCESS ? "" : localValue,
		localValue
	};
}

function validateRecipient() {
	if (
		!selectedRecipient.value.value ||
		!email.$validator(selectedRecipient?.value.value)
	) {
		return;
	}
	active.value = false;
	model.value = selectedRecipient.value;
}
</script>

<style lang="scss">
.ws-recipient {
	border: 1px solid $color-grey-300;
	border-radius: 6px;

	&:hover:not(.ws-recipient__active, .ws-recipient__disabled) {
		box-shadow: $box-shadow-radio-card-hover;
	}

	&__error {
		border-color: $color-danger-500;
	}
}
</style>
