import * as Excel from "exceljs";
import { ref } from "vue";
import i18n from "@/lang/lang.js";
import UnitsService from "@/services/v1/Units.service";
import {
	getDataFormats,
	getRegisterTypes
} from "@/helpers/protocols/modbus.helper";
import PropertiesService from "@/services/v1/Properties.service";
import { useI18n } from "vue-i18n";

const DATA_VALIDATIONS = {
	accessType: [
		"REMOTE_READ_ONLY",
		"REMOTE_WRITE_ONLY",
		"REMOTE_READ_WRITE",
		"LOCAL"
	],
	unit: [],
	disabled: ["ENABLE", "DISABLE"],
	modbusRegisterType: getRegisterTypes().map((type) => type.value),
	modbusDataFormat: getDataFormats(null, true).map((format) => format.value),
	bacnetObjectType: []
};

export function useXlsx() {
	UnitsService.getUnits().then((units) => {
		DATA_VALIDATIONS.unit = units.map((unit) => [unit.value]);
	});

	PropertiesService.getBacnetObjects().then((objects) => {
		if (!Array.isArray(objects)) {
			return;
		}
		DATA_VALIDATIONS.bacnetObjectType = objects.map((obj) => [obj.name]);
	});

	const { t: $t, availableLocales } = useI18n();

	const workbook = new Excel.Workbook();

	const allowedHeaders = ref([]);
	const requiredHeaders = ref([]);

	/**
	 * Find sheet in file through multi-language purpose
	 * Sheet name can be in French, English or German.
	 * @param {*} file
	 * @param {string} identifier
	 * @returns
	 */
	async function getWorksheet(file, identifier = null) {
		if (!identifier) {
			return await workbook.xlsx.load(file)?.worksheets[0];
		}

		const sheetNames = availableLocales.map((locale) => {
			return $t(identifier, 1, { locale });
		});

		const workFile = await workbook.xlsx.load(file);
		let sheet;

		workFile.worksheets.forEach((fileSheet) => {
			if (sheetNames.includes(fileSheet.name)) {
				sheet = fileSheet;
			}
		});

		if (!sheet) {
			throw new Error(i18n.t("missing-sheet-in-file"));
		}

		return sheet;
	}

	/**
	 *
	 * @param {worksheet} sheet
	 * @returns
	 */
	function validateHeaders(sheet) {
		const fileHeaders = sheet
			?.getRow(1)
			?.values.map((header) => header.trim());

		const hasRequiredHeaders = requiredHeaders.value.every((header) =>
			fileHeaders?.includes(header)
		);
		const hasNotAllowedHeaders =
			fileHeaders?.filter(
				(header) => !allowedHeaders.value.includes(header)
			) || [];

		if (!hasRequiredHeaders) {
			const missingHeaders = requiredHeaders.value.filter(
				(header) => !fileHeaders?.includes(header)
			);
			throw new Error(
				i18n.t("missing-column-in-file", {
					column: missingHeaders.join(", ").trim()
				})
			);
		}
		if (hasNotAllowedHeaders.length) {
			throw new Error(
				i18n.t("not-allowed-column-in-file", {
					column: hasNotAllowedHeaders.join(", ")
				})
			);
		}
		return fileHeaders;
	}

	/**
	 *
	 * @param {String[]} headers
	 * @returns {{header: string, key: string}[]}
	 */
	function injectHeaders(headers) {
		return headers.map((header) => ({
			header,
			key: header.trim()
		}));
	}

	/**
	 *
	 * @param {worksheet} sheet
	 * @returns
	 */
	function validateRows(sheet) {
		const missingRequiredDatas = [];
		requiredHeaders.value.forEach((requiredHeader) => {
			const column = sheet.getColumn(requiredHeader);
			const missingDatas = [];
			column.eachCell((cell, rowNumber) => {
				if (!cell.value && cell.value !== false) {
					missingDatas.push(rowNumber);
				}
			});

			if (missingDatas.length) {
				missingRequiredDatas.push({
					header: requiredHeader,
					rows: missingDatas
				});
			}
		});

		if (missingRequiredDatas.length) {
			throw new Error(
				i18n.t("missing-values", {
					values: missingRequiredDatas
						.map((data) => data.header)
						.join(", ")
				}),
				{
					cause: missingRequiredDatas
				}
			);
		}

		return true;
	}

	/**
	 * Extract and generate JSON from XSLX Sheet
	 * @param {worksheet} sheet
	 * @returns
	 */
	function generateJSON(sheet) {
		const content = [];
		sheet.eachRow(function (row, rowNumber) {
			// First row is header
			if (rowNumber === 1) {
				return;
			}
			const item = {};
			// Iterate over all cells in a row (including empty cells)
			row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
				let value = cell.value;

				if (cell.value?.richText) {
					value = cell.value.richText
						.flatMap((text) => text.text)
						.join("");
				}

				item[sheet.getColumn(colNumber).header] = value;
			});
			content.push(item);
		});

		return content;
	}

	/**
	 * Validate file with required headers and required rows data
	 * Get worksheet by name or first one
	 * @param {*} file
	 * @param {string} sheetName
	 * @returns
	 */
	async function validateFile(file, sheetName = null) {
		const sheet = await getWorksheet(file, sheetName);
		const fileHeaders = validateHeaders(sheet);
		sheet.columns = injectHeaders(fileHeaders);
		validateRows(sheet);

		return generateJSON(sheet);
	}

	/**
	 * Add Select to cell (aka DataValidation in lib)
	 */
	function addSelectValues(sheetTitle) {
		const worksheet = workbook.getWorksheet(sheetTitle);

		Object.keys(DATA_VALIDATIONS).forEach((validation) => {
			const hasColumn = worksheet.columns.find(
				({ key }) => key === validation
			);

			if (hasColumn) {
				const column = worksheet.getColumn(validation);
				let validations = `"${DATA_VALIDATIONS[validation].join(",")}"`; // Over-concatenation to keep uppercase

				/**
				 * Units need extra worksheet because Xls didn't allow list over 255 characters
				 */
				if (validation === "unit") {
					const unitSheet = workbook.addWorksheet("UNITS");
					const newRows = unitSheet.addRows(
						DATA_VALIDATIONS[validation]
					);
					validations = `UNITS!A1:A${newRows.length}`;
				}

				if (validation === "bacnetObjectType") {
					const typesSheet =
						workbook.addWorksheet("BACnetObjectTypes");
					const newRows = typesSheet.addRows(
						DATA_VALIDATIONS[validation]
					);
					validations = `BACnetObjectTypes!A1:A${newRows.length}`;
				}

				column.eachCell(function (cell, rowNumber) {
					if (rowNumber === 1) {
						return;
					}

					cell.dataValidation = {
						type: "list",
						allowBlank: false,
						formulae: [validations],
						showErrorMessage: true,
						errorStyle: "error",
						errorTitle: "Invalid value",
						error: i18n.t("configuration.bulk.export.cell.error")
					};
				});
			}
		});
	}

	/**
	 * Generate file from datas
	 * @param {*} datas
	 * @param {*} sheetTitle
	 * @param {*} sheetHeaders
	 * @returns File
	 */
	async function generateFile(
		datas,
		sheetTitle,
		sheetHeaders,
		hiddenColumns = null
	) {
		const title = sheetTitle ?? "Sheet 1";
		const sheet = workbook.addWorksheet(title);
		sheet.columns = sheetHeaders;

		if (hiddenColumns) {
			hideColumns(hiddenColumns, sheet);
		}

		datas.forEach((dataItem) => {
			sheet.addRow(dataItem);
		});

		addSelectValues(title);

		const file = await workbook.xlsx.writeBuffer();

		return file;
	}

	/**
	 * Based on imported file, inject Errors columns and rows error
	 * @param {*} file
	 * @param {array} errors
	 * @param {array} hiddenColumns
	 * @param {string} sheetName
	 * @returns {file}
	 */
	async function addErrors(
		file,
		errors,
		hiddenColumns = null,
		sheetName = null
	) {
		await workbook.xlsx.load(file);
		const sheet = await getWorksheet(file, sheetName);
		const fileHeaders = validateHeaders(sheet);

		const columns = injectHeaders(fileHeaders);
		if (!columns.find((col) => col?.key === "errors")) {
			columns.push({
				header: "errors",
				key: "errors"
			});
		}

		sheet.columns = columns;

		if (hiddenColumns) {
			hideColumns(hiddenColumns, sheet);
		}

		errors.forEach((error) => {
			/** error.index is API's payload index which trigger error
			 * Xlsx rows index begin at 1
			 * First row is headers
			 */
			if (typeof error.index !== "undefined") {
				const rowIndex = error.index + 2;
				const row = sheet.getRow(rowIndex);
				row.getCell("errors").value = error.message;
			}
		});

		const buffer = await workbook.xlsx.writeBuffer();
		return buffer;
	}

	function cleanup() {
		const ids = workbook.worksheets.map((sheet) => sheet.id);

		ids.forEach((id) => workbook.removeWorksheet(id));
	}

	function hideColumns(headers, sheet) {
		headers.forEach((header) => {
			sheet.getColumn(header).hidden = true;
		});

		return sheet;
	}

	return {
		workbook,
		requiredHeaders,
		allowedHeaders,
		validateFile,
		generateFile,
		addErrors,
		hideColumns,
		cleanup
	};
}
