<template>
	<div class="tabs-wrapper is-flex">
		<ws-button
			is-grey
			is-subtle
			@click="scrollLeft()"
			class="navigation-button mr-1"
			v-if="hasChevrons"
			v-tooltip="$t('previous')"
			:disabled="disableScrollLeft"
			data-testid="tabs-navigation-button-left"
		>
			<template #icon>
				<ws-icon icon="angle-left" />
			</template>
		</ws-button>

		<div
			class="tabs"
			:class="{ 'no-scroll': hasChevrons }"
			ref="tabsRef"
			data-testid="tabs-container"
		>
			<slot />
		</div>

		<ws-button
			is-grey
			is-subtle
			@click="scrollRight()"
			class="navigation-button ml-1"
			v-if="hasChevrons"
			v-tooltip="$t('next')"
			:disabled="disableScrollRight"
			data-testid="tabs-navigation-button-right"
		>
			<template #icon>
				<ws-icon icon="angle-right" />
			</template>
		</ws-button>

		<slot name="addBtn" />
	</div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { useLogger } from "@/plugins/logger/logger.plugin.js";
import { useI18n } from "vue-i18n";

const { error: $logError } = useLogger();
const { t: $t } = useI18n();
const tabsRef = ref(null);

const props = defineProps({
	showChevrons: {
		type: Boolean,
		default: false
	}
});

const availableWidth = ref(0);
const actualWidth = ref(0);
const scrollPosition = ref(0);
let resizeObserver = null;

onMounted(() => {
	updateWidths();

	try {
		// This is needed to, when tabs names or length change, to add/remove chevrons
		resizeObserver = new ResizeObserver(updateWidths);
		resizeObserver.observe(tabsRef.value);
	} catch (err) {
		$logError(err);
	}
});

onBeforeUnmount(() => {
	try {
		resizeObserver?.disconnect();
	} catch (err) {
		$logError(err);
	}
});

const hasScroll = computed(() => {
	return availableWidth.value < actualWidth.value;
});
const hasChevrons = computed(() => {
	return props.showChevrons && hasScroll.value;
});

const childrenRef = computed(() => {
	// go through all children until a ws-tabs-element is found
	let children = tabsRef.value?.children || [];
	while (children.length > 0) {
		if (children[0].classList.contains("ws-tabs-element")) {
			return children;
		}
		children = children[0].children;
	}
	return [];
});

const childrenPositions = computed(() => {
	const children = childrenRef.value;
	if (!children.length) {
		return [];
	}

	let positions = []; // store childs offsetLeft
	for (const child of children) {
		positions.push(child.offsetLeft);
	}
	// first element should be at position 0, so offset the others
	const offset = positions[0];
	positions = positions.map((left) => left - offset);
	return positions;
});

const disableScrollLeft = computed(() => scrollPosition.value <= 0);
const disableScrollRight = computed(
	() =>
		childrenPositions.value[scrollPosition.value] + availableWidth.value >
		actualWidth.value
);

const updateWidths = () => {
	availableWidth.value = tabsRef.value?.clientWidth || 0;
	actualWidth.value = tabsRef.value?.scrollWidth || 0;
};

function scrollTo(position) {
	tabsRef.value.scrollTo({
		top: 0,
		left: position,
		behavior: "smooth"
	});
}

const scrollRight = () => {
	scrollPosition.value += 1;
	scrollTo(childrenPositions.value[scrollPosition.value]);
};
const scrollLeft = () => {
	scrollPosition.value -= 1;
	scrollTo(childrenPositions.value[scrollPosition.value]);
};
const scrollToStart = () => {
	tabsRef.value.scrollBy(-actualWidth.value, 0);
};
const scrollToEnd = () => {
	setTimeout(() => {
		tabsRef.value?.scrollBy(actualWidth.value, 0);
	}, 100);
};
defineExpose({ scrollToEnd, scrollToStart });
</script>

<style lang="scss" scoped>
.tabs-wrapper {
	border-bottom: 1px solid $border-color;

	margin-bottom: 1rem;

	margin-right: -1rem;
	margin-left: -1.5rem;

	padding-right: 1rem;
	padding-left: 1.5rem;
}
.tabs {
	position: relative; // needed to calculate offsets correctly
	margin: 0 !important;
	justify-content: flex-start;
	font-weight: 600;
	font-size: 1rem;
	&.no-scroll {
		overflow: hidden;
	}
}
.navigation-button {
	margin-top: 1rem;
}
</style>
