/**
 * Date Range Picker Component
 * Based on VueRangedatePicker
 * https://github.com/bliblidotcom/vue-rangedate-picker
 *
 * NB: Date/Time functionality is UTC-based
 */

import { generateRandomId } from "@/helpers/functions.helper.js";
const defaultConfig = { timepicker: false };

const defaultLang = "en";
const availableMonths = {
	en: [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December"
	],
	fr: [
		"Janvier",
		"Février",
		"Mars",
		"Avril",
		"Mai",
		"Juin",
		"Juillet",
		"Août",
		"Septembre",
		"Octobre",
		"Novembre",
		"Decembre"
	]
};

const availableShortDays = {
	en: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
	fr: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"]
};

const defaultCaptions = {
	title: "Choose Date"
};

const defaultStyle = {
	daysWeeks: "calendar_weeks",
	days: "calendar_days",
	daysSelected: "calendar_days_selected",
	daysInRange: "calendar_days_in-range",
	dateDisabled: "calendar_days--disabled"
};

const today = new Date();
export default {
	name: "rangedate-picker",
	props: {
		modelValue: {
			type: null,
			default: () => {
				return [];
			}
		},
		id: {
			type: String,
			default: () => {
				return generateRandomId();
			}
		},
		label: {
			type: String,
			default: null
		},
		labelDescription: {
			type: String,
			default: null
		},
		tooltip: {
			type: String,
			default: null
		},
		tooltipPosition: {
			type: String,
			default: "top"
		},
		error: {
			type: [String, Boolean, null],
			default: false
		},
		configs: {
			type: Object,
			default: () => defaultConfig
		},
		emptyMsg: {
			type: String,
			default: "Select Date"
		},
		lang: {
			type: String,
			default: defaultLang
		},
		months: {
			type: Array,
			default: () => null
		},
		shortDays: {
			type: Array,
			default: () => null
		},
		// options for captions are: title
		captions: {
			type: Object,
			default: () => defaultCaptions
		},
		format: {
			type: String,
			default: "MMM DD, YYYY"
		},
		timeFormat: {
			type: String,
			default: "HH:mm"
		},
		style: {
			type: String,
			default: null
		},
		startActiveMonth: {
			type: Number,
			default: today.getMonth()
		},
		startActiveYear: {
			type: Number,
			default: today.getFullYear()
		},
		rightToLeft: {
			type: String,
			default: "false"
		},
		disabled: {
			type: Boolean,
			default: false
		},
		isInline: {
			type: Boolean,
			default: false
		}
	},

	emits: ["update:modelValue"],

	data() {
		return {
			dateRange: null,
			selectedRange: null,
			numOfDays: 7,
			isOpen: false,
			showMonth: false,
			activeMonthStart: this.startActiveMonth,
			activeYearStart: this.startActiveYear,
			activeYearEnd: this.startActiveYear
		};
	},

	created() {
		if (this.activeMonthStart === 11) {
			this.activeYearEnd = this.activeYearStart + 1;
		}
	},

	watch: {
		startNextActiveMonth: function (value) {
			if (value === 0) {
				this.activeYearEnd = this.activeYearStart + 1;
			}
		},
		modelValue: {
			handler: function (_newValue) {
				if (_newValue) {
					this.dateRange = new Date(_newValue) || null;
					this.selectedRange = this.dateRange;
					this.activeMonthStart = this.selectedRange.getMonth();
					this.activeYearStart = this.selectedRange.getFullYear();
					this.activeYearEnd = this.selectedRange.getFullYear();
				}
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		monthsLocale: function () {
			return this.months || availableMonths[this.lang];
		},
		shortDaysLocale: function () {
			return this.shortDays || availableShortDays[this.lang];
		},
		s: function () {
			return Object.assign({}, defaultStyle, this.style);
		},
		startMonthDay: function () {
			return new Date(
				Date.UTC(this.activeYearStart, this.activeMonthStart, 1)
			).getDay();
		},

		endMonthDate: function () {
			return new Date(
				Date.UTC(this.activeYearEnd, this.startNextActiveMonth, 0)
			).getDate();
		},
		endNextMonthDate: function () {
			return new Date(
				Date.UTC(this.activeYearEnd, this.activeMonthStart + 2, 0)
			).getDate();
		},
		startNextActiveMonth: function () {
			return this.activeMonthStart >= 11 ? 0 : this.activeMonthStart + 1;
		}
	},

	methods: {
		close: function () {
			this.isOpen = false;
			this.showMonth = false;
		},
		toggleCalendar: function () {
			// reset selected range, it's not applied anyway
			this.isOpen = !this.isOpen;
			this.showMonth = !this.showMonth;
		},

		getDateByFormat: function (date, format, lang) {
			if (!date) {
				return null;
			}
			return (
				this.$dayjs(date)
					// .utc()
					.locale(lang || this.lang)
					.format(format)
			);
		},

		getDateString: function (date, format = this.format) {
			return this.getDateByFormat(date, format);
		},

		getTimeString: function (date, format = this.timeFormat) {
			return this.getDateByFormat(date, format);
		},

		getDayIndexInMonth: function (r, i, startMonthDay) {
			const date = this.numOfDays * (r - 1) + i;
			return date - startMonthDay;
		},

		getDayCell(r, i, startMonthDay, endMonthDate) {
			const result = this.getDayIndexInMonth(r, i, startMonthDay);
			// bound by > 0 and < last day of month
			return result > 0 && result <= endMonthDate
				? `${result}`
				: // : "&nbsp;";
					"";
		},

		getNewDateRange(result, activeMonth, activeYear) {
			const resultDate = new Date(
				Date.UTC(activeYear, activeMonth, result)
			);
			return resultDate;
		},

		selectFirstItem(r, i) {
			const result = this.getDayIndexInMonth(r, i, this.startMonthDay);
			this.dateRange = this.getNewDateRange(
				result,
				this.activeMonthStart,
				this.activeYearStart
			);
			this.setDateValue();
		},

		isDateSelected(r, i, key, startMonthDay, endMonthDate) {
			const result = this.getDayIndexInMonth(r, i, startMonthDay);
			if (result < 1 || result > endMonthDate) {
				return false;
			}

			let currDate = null;
			currDate = new Date(
				Date.UTC(this.activeYearStart, this.activeMonthStart, result)
			);

			return (
				this.dateRange &&
				// new Date(this.dateRange).getTime() === currDate.getTime()
				this.$dayjs(this.dateRange.getTime()).isSame(
					currDate.getTime(),
					"day"
				)
			);
		},

		isDateDisabled(r, i, key, startMonthDay, endMonthDate) {
			const result = this.getDayIndexInMonth(r, i, startMonthDay);
			const resultDate = new Date(
				Date.UTC(this.activeYearStart, this.activeMonthStart, result)
			);
			if (
				this.configs.maxDate &&
				this.$dayjs(resultDate).isAfter(this.configs.maxDate)
			) {
				return true;
			}
			if (
				this.configs.minDate &&
				this.$dayjs(resultDate).isBefore(this.configs.minDate)
			) {
				return true;
			}

			// bound by > 0 and < last day of month
			return !(result > 0 && result <= endMonthDate);
		},
		goPrevYear() {
			const prevYear = new Date(
				Date.UTC(this.activeYearStart - 1, this.activeMonthStart, 12)
			);
			this.activeMonthStart = prevYear.getMonth();
			this.activeYearStart = prevYear.getFullYear();
			this.activeYearEnd = prevYear.getFullYear();
		},

		goNextYear() {
			const nextYear = new Date(
				Date.UTC(this.activeYearEnd + 1, this.activeMonthStart, 12)
			);
			this.activeMonthStart = nextYear.getMonth();
			this.activeYearStart = nextYear.getFullYear();
			this.activeYearEnd = nextYear.getFullYear();
		},

		goPrevMonth() {
			const prevMonth = new Date(
				Date.UTC(this.activeYearStart, this.activeMonthStart, 0)
			);
			this.activeMonthStart = prevMonth.getMonth();
			this.activeYearStart = prevMonth.getFullYear();
			this.activeYearEnd = prevMonth.getFullYear();
		},
		goNextMonth() {
			const nextMonth = new Date(
				Date.UTC(this.activeYearEnd, this.startNextActiveMonth, 1)
			);
			this.activeMonthStart = nextMonth.getMonth();
			this.activeYearStart = nextMonth.getFullYear();
			this.activeYearEnd = nextMonth.getFullYear();
		},

		setDateValue: function () {
			this.selectedRange = this.dateRange;

			this.$emit("update:modelValue", this.selectedRange);
			this.toggleCalendar();
		},

		canSetDateRange(code) {
			if (!this.configs || !this.configs.minDate) {
				return true;
			}
			switch (code) {
				case "last2Days": {
					return this.$dayjs()
						.subtract(1, "day")
						.isAfter(this.configs.minDate);
				}
				case "last7Days": {
					return this.$dayjs()
						.subtract(7, "day")
						.isAfter(this.configs.minDate);
				}
				case "last14Days": {
					return this.$dayjs()
						.subtract(14, "day")
						.isAfter(this.configs.minDate);
				}
				case "last30Days": {
					return this.$dayjs()
						.subtract(30, "day")
						.isAfter(this.configs.minDate);
				}
				case "last90Days": {
					return this.$dayjs()
						.subtract(90, "day")
						.isAfter(this.configs.minDate);
				}
				case "lastYear": {
					return this.$dayjs()
						.subtract(1, "year")
						.isAfter(this.configs.minDate);
				}
				default: {
					break;
				}
			}
		}
	}
};
