import { computed } from "vue";
import {
	allowedCsvFileHeaders as lorawanAllowedCsvFileHeaders,
	requiredCsvFileHeaders as lorawanRequiredCsvFileHeaders,
	formatLine as lorawanFormatLine
} from "@/components/ws-dropzone/helpers/objects/lorawan-import-csv.helpers.js";

import {
	allowedCsvFileHeaders as modbusAllowedCsvFileHeaders,
	requiredCsvFileHeaders as modbusRequiredCsvFileHeaders,
	formatLine as modbusFormatLine
} from "@/components/ws-dropzone/helpers/objects/modbus-csv.helpers.js";

import {
	allowedFileHeaders as equipmentsAllowedFileHeaders,
	requiredFileHeaders as equipmentsRequiredFileHeaders,
	headerToApiKeys as equipmentsHeaderToApiKeys,
	formatLine as equipmentsFormatLine,
	formatToFile as equipmentFormatToFile
} from "@/components/ws-dropzone/helpers/objects/equipments-import.helpers.js";

import {
	allowedFileHeaders as propertiesAllowedFileHeaders,
	requiredFileHeaders as propertiesRequiredFileHeaders,
	headerToApiKeys as propertiesHeaderToApiKeys,
	formatLine as propertiesFormatLine,
	formatToFile as propertiesFormatToFile
} from "@/components/ws-dropzone/helpers/objects/properties-import.helpers.js";

import {
	allowedFileHeaders as redirectedPropertiesAllowedFileHeaders,
	requiredFileHeaders as redirectedPropertiesRequiredFileHeaders,
	headerToApiKeys as redirectedPropertiesHeaderToApiKeys,
	formatLine as redirectedPropertiesFormatLine,
	formatToFile as redirectPropertiesFormatToFile
} from "@/components/ws-dropzone/helpers/objects/redirected-properties-import.helpers.js";
import { useLogMonitoring } from "@/plugins/user-console-monitoring/useLogMonitoring";

/**
 * Get parameters for export file by Object type
 * @param {Ref} identifier
 * @returns
 */
export function useObjectImport(identifier) {
	const { notify } = useLogMonitoring();
	const config = computed(() => {
		switch (identifier.value) {
			case "lorawan":
				return {
					allowedHeaders: lorawanAllowedCsvFileHeaders,
					requiredHeaders: lorawanRequiredCsvFileHeaders,
					formatToPreview: (arr) =>
						arr.map((line) => lorawanFormatLine(line))
				};
			case "modbus":
				return {
					allowedHeaders: modbusAllowedCsvFileHeaders,
					requiredHeaders: modbusRequiredCsvFileHeaders,
					formatToPreview: (arr) =>
						arr.map((line) => modbusFormatLine(line))
				};
			case "equipments":
				return {
					allowedHeaders: equipmentsAllowedFileHeaders,
					requiredHeaders: equipmentsRequiredFileHeaders,
					headerToApiKeys: equipmentsHeaderToApiKeys,
					formatToPreview: (arr) =>
						arr.map((line) => equipmentsFormatLine(line)),
					formatToFile: equipmentFormatToFile
				};
			case "properties":
				return {
					allowedHeaders: propertiesAllowedFileHeaders,
					requiredHeaders: propertiesRequiredFileHeaders,
					headerToApiKeys: propertiesHeaderToApiKeys,
					formatToPreview: (arr) =>
						arr.map((line) => propertiesFormatLine(line)),
					formatToFile: propertiesFormatToFile
				};
			case "redirected-properties":
				return {
					allowedHeaders: redirectedPropertiesAllowedFileHeaders,
					requiredHeaders: redirectedPropertiesRequiredFileHeaders,
					headerToApiKeys: redirectedPropertiesHeaderToApiKeys,
					formatToPreview: (arr) =>
						arr.map((line) => redirectedPropertiesFormatLine(line)),
					formatToFile: redirectPropertiesFormatToFile
				};
			default:
				notify("No identifier set");
				return {
					allowedHeaders: [],
					requiredHeaders: [],
					formatToPreview: () => ({}),
					formatToFile: () => ({})
				};
		}
	});

	return {
		config
	};
}
